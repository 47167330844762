import { Element } from 'react-scroll';

export default function Contact() {
    return (
        <Element name="contact">
            <div className='container mx-auto pt-8 pb-24 px-6 lg:px-0'>
                <p className='text-center opacity-50 pb-1 text-syne font-bold text-white'>Any question?</p>
                <p className='text-center text-4xl pb-8 idea-ai font-bold text-white'>Get in touch</p>
                <div className='grid grid-cols-1 gap-8'>
                    <div className='w-full bg-transparent border-b-makit h-auto rounded-2xl relative p-8 question'>
                        
                        <div className='self-end text-white p-2 rounded-md bg-[#191a1e] mb-4 question-contact opacity-0'>
                            <div className='m-2' style={{ textAlign: "left" }}>
                                <div
                                    className='p-2 rounded-3xl'
                                    style={{
                                        color: "#525252",
                                        backgroundColor: "#191a1e"
                                    }}
                                >
                                <div className="text-white opacity-50 text-syne pb-2">
                                    <p><i className='fa-solid fa-robot pr-2' />Makit</p>
                                </div>
                                    Hey there, how can I help you?
                                </div>
                            </div>
                        </div>

                        <div className='self-end text-white p-2 rounded-md bg-[#191a1e] mb-4 ask-contact opacity-0'>
                            <div className='m-2' style={{ textAlign: "right" }}>
                                <div
                                    className='p-2 rounded-3xl'
                                    style={{
                                        color: "#525252",
                                        backgroundColor: "#191a1e"
                                    }}
                                >
                                <div className="text-white opacity-50 text-syne pb-2">
                                    <p><i className='fa-solid fa-user pr-2' />User</p>
                                </div>
                                    I would like to get in touch with your creators
                                </div>
                            </div>
                        </div>

                        <div className='text-white p-2 rounded-md mb-4 text-center mt-16 send-email opacity-0'>
                            
                            <div className="bg-gradient-to-r from-blue-500 to-violet-500 text-black py-8 text-4xl rounded-2xl font-bold">
                                <a href="mailto:hi@makit.ai">hi@makit.ai</a>
                                <p className="text-sm opacity-50 pt-2"><a href="https://discord.gg/hjUU6yP8vH">Or Join our Discord Server</a></p>
                            </div>
                        </div>

                    </div>
                </div> 
            </div>
        </Element>
    )
}