export default function Footer() {
    return (
        <div className='container mx-auto text-poppins px-6 lg:px-0'>
            <div className='border-t-makit'>
                <div className='grid grid-cols-1 lg:grid-cols-3 text-white py-6 gap-6 lg:gap-0'>
                    <div className='self-center text-center lg:text-left'>
                        <p className='opacity-50 hover:opacity-100 focus:opacity-100'>© 2024 Makit.</p>
                        <p className='opacity-50 text-xs'>All Rights Reserved.</p>
                    </div>
                    <div className='text-center self-center'>
                        <a href="https://drive.google.com/file/d/1xk4EXkYMc6ZqAuOA2PHS8Jg-D_m-Qs4W/view?usp=sharing">
                            <p className='inline-block opacity-50 hover:opacity-100 focus:opacity-100 px-2'>Terms of Services</p>
                        </a>
                        <a href="https://drive.google.com/file/d/1I_9KSAMsWA7py8s3hjLuYbAQWhNtP2t-/view?usp=sharing">
                            <p className='inline-block opacity-50 hover:opacity-100 focus:opacity-100 px-2'>Privacy Policy</p>
                        </a>
                        <a href="https://discord.gg/NjSypPddNe">
                            <p className='inline-block opacity-50 hover:opacity-100 focus:opacity-100 px-2'>Careers</p>
                        </a>
                    </div>
                    <div>
                        <p className='text-center lg:text-right text-xl self-center'>
                            <a href="https://discord.gg/NjSypPddNe" className='px-1 opacity-50 hover:opacity-100 focus:opacity-100'><i className="fab fa-discord"></i></a>
                            <a href="https://www.instagram.com/versadia.dev/" className='px-1 opacity-50 hover:opacity-100 focus:opacity-100'><i className="fab fa-instagram-square"></i></a>
                            <a href="https://twitter.com/versadiadev" className='px-1 opacity-50 hover:opacity-100 focus:opacity-100'><i className="fab fa-twitter-square"></i></a>
                            <a href="https://www.linkedin.com/company/versadia" className='px-1 opacity-50 hover:opacity-100 focus:opacity-100'><i className="fab fa-linkedin"></i></a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}