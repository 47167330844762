const team = [
    {
        "id": 1,
        "name": "Luca Tropea",
        "title": "CEO & Lead Developer",
        "image": "/team/lucatropea.png",
    },
    {
        "id": 3,
        "name": "Ion Spinu",
        "title": "COO & HR Manager",
        "image": "/team/ion.png",
    },
    {
        "id": 4,
        "name": "Vincenzo Guarnieri",
        "title": "CCO & Developer",
        "image": "/team/vincenzoguarnieri.png",
    },
    {
        "id": 5,
        "name": "Domenico Iemma",
        "title": "Community Manager",
        "image": "/team/domenicoiemma.png",
    },
    {
        "id": 6,
        "name": "Marco Tropea",
        "title": "Legal Consultant",
        "image": "/team/marcotropea.png",
    },
    {
        "id": 7,
        "name": "Dario Codispoti",
        "title": "Advisor | Co-Founder @ NovisGames",
        "image": "/team/dariocodispoti.png",
    },
    {
        "id": 8,
        "name": "Gabriele Chini",
        "title": "Advisor | CEO @ Mangrovia SRL",
        "image": "/team/chini.png",
    }
];

export default team;
