export default function Values() {
    return (
        <div className='bg-white py-16 about'>
            <div className='container mx-auto'>
                <div className='text-center'>
                    <p className='opacity-50 pb-1 text-syne font-bold'>Why Makit</p>
                    <p className='text-2xl lg:text-4xl pb-8 lg:pb-16 idea-ai font-bold'>From an Idea to an AI</p>
                    <div className='grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-12 px-6 lg:px-0'>

                        <div className='border-makit bg-black rounded-xl card relative focus:scale-105 hover:scale-105 transition-all ease-linear'>
                            <div className='bg-gradient-to-r from-cyan-500 to-blue-500 w-full h-full absolute rounded-xl opacity-20'></div>
                            <div className='p-8'>
                                <div className='h-16 mb-4'>
                                    <img src='/icons/speed.svg' className='h-full mx-auto mb-6' alt="Mission" />
                                </div>
                                <p className='text-2xl pb-2 text-white'>Our Mission</p>
                                <p className='subtitle text-poppins text-sm'>We aim to provide innovative tools that empower both individuals and businesses to harness the full potential of AI, advancing technology inclusively and sustainably</p>
                            </div>
                        </div>

                        <div className='border-makit bg-black rounded-xl card relative focus:scale-105 hover:scale-105 transition-all ease-linear'>
                            <div className='bg-gradient-to-r from-blue-500 to-violet-500 w-full h-full absolute rounded-xl opacity-20'></div>
                            <div className='p-8'>
                                <div className='h-16 mb-4'>
                                    <img src='/icons/eye.svg' className='h-full mx-auto mb-6' alt="Vision" />
                                </div>
                                <p className='text-2xl pb-2 text-white'>Our Vision</p>
                                <p className='subtitle text-poppins text-sm'>We envision a world where AI is a tool accessible to all, where creativity and innovation drive the development of intelligent solutions that enhance daily life</p>
                            </div>
                        </div>

                        <div className='border-makit bg-black rounded-xl card relative focus:scale-105 hover:scale-105 transition-all ease-linear'>
                            <div className='bg-gradient-to-r from-cyan-500 to-emerald-500 w-full h-full absolute rounded-xl opacity-20'></div>
                            <div className='p-8'>
                                <div className='h-16 mb-4'>
                                    <img src='/icons/globe.svg' className='h-full mx-auto mb-6' alt="Values" />
                                </div>
                                <p className='text-2xl pb-2 text-white'>Our Values</p>
                                <p className='subtitle text-poppins text-sm'>Transparent and ethical collaboration is at the core of our philosophy, working with the global community to create an ecosystem that reflects the diversity of perspectives</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}