import { useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Element } from 'react-scroll';

import SplashText from "../components/splash/SplashText"
import Demo from "../components/splash/Demo"

export default function Splash() {

    useEffect(() => {
        let ctx = gsap.context(() => {

            gsap.registerPlugin(ScrollTrigger);
            const tl = gsap.timeline();

            gsap.to('.splash-text', {
                opacity: 0,
                y: 300,
                scrollTrigger: {
                    trigger: '.value-proposition',
                    start: 'top top',
                    end: 'bottom top',
                    onRefresh: self => self.progress && self.animation.progress(1),
                    scrub: true,
                }
            })

            gsap.to('.video', {
                zoom: 1.1,
                scrollTrigger: {
                    trigger: '.value-proposition',
                    start: 'top top',
                    end: 'bottom top',
                    onRefresh: self => self.progress && self.animation.progress(1),
                    scrub: true
                }
            })

            gsap.to('.card-feature', {
                opacity: 1,
                y: -50,
                scrollTrigger: {
                    trigger: '.features',
                    start: 'top bottom',
                    end: 'bottom bottom',
                    onRefresh: self => self.progress && self.animation.progress(1),
                    scrub: true
                }
            })

            gsap.to('.card-feature', {
                opacity: 1,
                y: -50,
                scrollTrigger: {
                    trigger: '.features',
                    start: 'top bottom',
                    end: 'bottom bottom',
                    onRefresh: self => self.progress && self.animation.progress(1),
                    scrub: true
                }
            })

            ScrollTrigger.create({
                animation: tl,
                trigger:".question",
                start: "top 60%",
                end: "bottom 60%",
                ease: "easeIn",
                onRefresh: self => self.progress && self.animation.progress(1),
                scrub: true
            })

            tl
            .to('.question-contact', 1, { opacity: 1 })
            .to('.ask-contact', 5, { opacity: 1 })
            .to('.send-email', 10, { opacity: 1, scale: 1.02 })

        })
        return () => ctx.revert();
    }, []);

    return (
        <Element name="splash">
            <div className='h-auto w-full z-40 relative'>
                <div className="w-full h-full absolute z-10 opacity-20 bg-pattern"></div>
                <div className='mx-auto text-center pt-32 pb-16 z-20 relative lg:pb-32 lg:pt-32 text-white'>
                    <SplashText />
                    <Demo />
                </div>
            </div>
        </Element>
    )
}