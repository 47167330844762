export default function SplashText() {
    return (
        <>
        <div className='splash-text px-6 lg:px-0 block lg:hidden'>
            <p className='text-2xl lg:text-6xl font-makitbold bg-clip-text text-transparent pb-4 bg-gradient-to-b from-indigo-500 via-purple-500 to-pink-900'>
                The Ultimate Copilot<br/>
                To Create Your Unique AI
            </p>
            <p className='text-l lg:text-xl opacity-20 pb-16 nc'>A No-code Solution for Your Problems</p>
            <a href="https://app.makit.ai/" className='trymakit text-l bg-purple-600 px-12 py-4 rounded-l text-black font-makitbold z-30 relative' >Try Makit</a>
            <div className='mt-6 lg:hidden block'>
                <a className='text-l text-purple-200 opacity-50 z-30 relative' href="https://www.youtube.com/watch?v=9veODCn8kww&ab_channel=Versadia"><i className="fas fa-play text-xs pr-1 self-center"></i> Watch the Trailer</a>
            </div>
        </div>
        <div className='splash-text px-6 lg:px-0 hidden lg:block'>
            <p className='value-proposition text-2xl lg:text-6xl font-makitbold bg-clip-text text-transparent pb-4 bg-gradient-to-b from-indigo-500 via-purple-500 to-pink-900'>
                The Ultimate Copilot<br/>
                To Create Your Unique AI
            </p>
            <p className='text-l lg:text-xl opacity-20 pb-16 nc'>A No-code Solution for Your Problems</p>
            <a href="https://app.makit.ai/" className='trymakit text-l bg-purple-600 px-12 py-4 rounded-l text-black font-makitbold z-30 relative' >Try Makit</a>
            <div className='mt-6 lg:hidden block'>
                <a className='text-l text-purple-200 opacity-50 z-30 relative' href="https://www.youtube.com/watch?v=9veODCn8kww&ab_channel=Versadia"><i className="fas fa-play text-xs pr-1 self-center"></i> Watch the Trailer</a>
            </div>
        </div>
        </>
    )
}