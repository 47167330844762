import team from '../utils/Team';
import { Element } from 'react-scroll';

export default function Team() {
    return (
        <Element name="team">
            <div className='py-16 lg:px-0 px-6' id="team">
                <div className='container mx-auto text-white'>
                    <p className='text-center opacity-50 pb-1 text-syne font-bold'><i className="far fa-hand-peace"></i> AI Need Humans</p>
                    <p className='text-center text-4xl pb-16 idea-ai font-bold'>Our Core Team</p>
                    <div className='grid grid-cols-1 lg:grid-cols-3 gap-8 relative'>
                        {team.map(member => (
                            <div key={member.id} className='rounded-xl border-b-makit text-center p-8'>
                                <img className='mb-4 rounded-xl w-3/4 mx-auto' src={member.image} alt={member.name} />
                                <p className='text-xl font-bold pb-1'>{member.name}</p>
                                <p className='text-sm text-poppins opacity-50'>{member.title}</p>
                                <p className='text-sm text-poppins opacity-50'>{ (member.subtitle) && ( <span>{member.subtitle}</span> )}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Element>
    )
}