import React from 'react';
import ReactPlayer from 'react-player';

export default function Demo() {

    return (
        <div className='video container mx-auto mt-24 z-20 relative hidden lg:block'>
            <ReactPlayer
                url="banner.webm"
                playing={true}
                muted={true}
                loop={true}
                width="80%"
                height="80%"
                className='mx-auto'
            />
        </div>
    );
}
