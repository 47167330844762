import { Element } from 'react-scroll';

export default function Features() {
    return (
        <Element name="features">
            <div className='bg-black pt-16 mb-8 features-sec relative px-6 lg:px-0'>
                <div className="w-full h-full absolute z-10 opacity-20 bg-pattern"></div>

                <div className='container mx-auto text-white z-20 relative'>
                    <p className='opacity-50 pb-1 text-syne font-bold text-center'><i className="fas fa-rocket text-xs pr-1"></i> Features</p>
                    <p className='text-4xl pb-20 lg:pb-24 idea-ai font-bold text-center'>At Your Service</p>
                    <div className='grid grid-cols-1 lg:grid-cols-6 relative'>

                        <div />
                        <div className='lg:col-span-4 grid grid-cols-1 lg:grid-cols-6 gap-4 features'>
                            
                            <div className='lg:col-span-3 p-6 border-b-makit bg-makit text-white rounded-2xl text-center card-feature'>
                                <div className='h-auto'>
                                    <img className='mb-4 rounded-l w-full' src='/images/easy.png' alt="Built for Simplicity" />
                                </div>
                                <p className='text-syne'>Built for Simplicity</p>
                                <p className='subtitle text-xs'>The interface is simple and intuitive</p>
                            </div>

                            <div className='lg:col-span-3 p-6 border-b-makit bg-makit text-white rounded-2xl text-center card-feature'>
                                <div className='h-auto'>
                                    <img className='mb-4 rounded-l w-full' src='./images/services.svg' alt="External Integrations" />
                                </div>
                                <p className='text-syne'>External Integrations</p>
                                <p className='subtitle text-xs'>Automate daily processes</p>
                            </div>

                            <div className='aa col-span-1 lg:col-span-2 p-6 border-b-makit bg-makit text-white rounded-2xl text-center card-feature'>
                                <p className='text-syne'>Monetize your AI</p>
                                <p className='subtitle text-xs'>Release it on our Marketplace</p>
                            </div>

                            <div className='col-span-1 lg:col-span-2 p-6 border-b-makit bg-makit text-white rounded-2xl text-center card-feature'>
                                <p className='text-syne'>Documentation Analytics</p>
                                <p className='subtitle text-xs'>Understand How to Improve your AI </p>
                            </div>

                            <div className='col-span-1 lg:col-span-2 p-6 border-b-makit bg-makit text-white rounded-2xl text-center card-feature'>
                                <p className='text-syne'>Share your Creations</p>
                                <p className='subtitle text-xs'>Share your AI with your friends</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </Element>
    )
}