import Values from '../components/about/Values'
import Features from '../components/about/Features'

export default function About () {
    return (
        <>
            <Values />
            <Features />
        </>
    )
}