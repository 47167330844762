import { Element } from 'react-scroll';

export default function Marketplace() {
    return (
        <Element name="marketplace">
            <div className='container mx-auto pt-8 pb-24'>
                <p className='text-center opacity-50 pb-1 text-syne font-bold text-white'>Share Your Creations</p>
                <p className='text-center text-4xl pb-16 idea-ai font-bold text-white'>Marketplace</p>
                <div className='grid grid-cols-1 lg:grid-cols-4 px-6 lg:px-0 gap-8 [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)]'>

                    <div className='border-makit text-white text-center p-8 bg-gradient-to-t from-indigo-500 to-sky-500 rounded-xl'>
                        <p className='text-xl font-bold'>Web3 Assistant</p>
                        <p className='pb-6'><span className='opacity-50'>Author:</span> @ionspinu</p>
                        <a href="https://www.app.makit.ai/apps/asst_U1I1WRqP7f8hVg57Li2HgSJk" className='bg-white text-indigo-500 px-8 py-2 rounded-xl opacity-20'>Chat</a>
                    </div>

                    <div className='border-makit text-white text-center p-8 bg-gradient-to-t from-indigo-500 to-sky-500 rounded-xl'>
                        <p className='text-xl font-bold'>Hytopia Wiki</p>
                        <p className='pb-6'><span className='opacity-50'>Author:</span> @versadia</p>
                        <a href="https://app.makit.ai/hytopia" className='bg-white text-indigo-500 px-8 py-2 rounded-xl opacity-20'>Chat</a>
                    </div>

                    <div className='border-makit text-white text-center p-8 bg-gradient-to-t from-indigo-500 to-sky-500 rounded-xl'>
                        <p className='text-xl font-bold'>MAMMA MIA! 🍕</p>
                        <p className='pb-6'><span className='opacity-50'>Author:</span> @lucat</p>
                        <a href="https://www.app.makit.ai/apps/asst_iwhzjCwDDYpTJYNylHI4RO1H" className='bg-white text-indigo-500 px-8 py-2 rounded-xl opacity-20'>Chat</a>
                    </div>

                    <div className=' border-makit text-white text-center p-8 bg-gradient-to-t from-indigo-500 to-sky-500 rounded-xl'>
                        <p className='text-xl font-bold'>Basketball Trainer 🏀</p>
                        <p className='pb-6'><span className='opacity-50'>Author:</span> @achine001</p>
                        <a href="https://www.app.makit.ai/apps/asst_BTwCSJqeJODbYqIqsdvJlHYo" className='bg-white text-indigo-500 px-8 py-2 rounded-xl opacity-20'>Chat</a>
                    </div>

                </div> 
            </div>
        </Element>
    )
}