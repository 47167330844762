import React, { useState } from 'react'
import { Squash as Hamburger } from 'hamburger-react'
import { Link } from 'react-scroll';

import routes from '../utils/Routes';

export default function Navigation() {
    const [isOpen, setOpen] = useState(false)
    
    if(isOpen) {
        document.body.classList.add('overflow-hidden')
    } else {
        document.body.classList.remove('overflow-hidden')
    }
    return (
        <>
        <div className='fixed top-0 mx-auto justify-center w-full z-50 navigation border-bot-makit'>
            <div className='container mx-auto text-[#A0A0A0]'>
                <div className='grid grid-cols-2 lg:grid-cols-4 py-2 px-6 lg:px-0'>
                    <div className='lg:flex items-center hidden'>
                        <Link to="splash" spy={true} smooth={true} duration={500} className='text-white text-xl font-makitbold cursor-pointer hover:cursor-pointer'>
                            makit
                        </Link>
                    </div>
                    <div className='col-span-2 text-center self-center block lg:hidden'>
                        <Link to="splash" spy={true} smooth={true} duration={500} className='text-white text-xl font-makitbold cursor-pointer hover:cursor-pointer'>
                            makit
                        </Link>
                    </div>
                    <div className='col-span-2 text-center self-center hidden lg:block'>
                        {Object.keys(routes).map((routeKey) => {
                            const routeDisplayName = routeKey.replace('_', ' ');
                            const routePath = routes[routeKey];

                            return (
                                <div key={routePath} className='inline-block mx-4 hover:text-white focus:text-white cursor-pointer hover:cursor-pointer'>
                                    <Link to={routePath} spy={true} smooth={true} duration={500} className='cursor-pointer hover:cursor-pointer'>
                                        {routeDisplayName}
                                    </Link>
                                </div>
                            );
                        })}
                    </div>
                    <div className='self-center text-right hidden lg:block'>
                        <a href="https://app.makit.ai/">
                            <p className='inline-block text-white bg-neutral-600 px-6 py-1 rounded-l font-bold text-sm'>Try Makit</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        { isOpen && (
            <div className="fixed z-10 top-0 w-full h-screen bg-black text-white menuOverflow flex justify-center items-center">
                <div className="w-full h-full absolute z-10 opacity-20 bg-pattern"></div>
                <div>
                    {Object.keys(routes).map((routeKey) => {
                        const routeDisplayName = routeKey.replace('_', ' ');
                        const routePath = routes[routeKey];

                        return (
                            <div key={routePath} className='text-center text-4xl my-4 hover:text-white focus:text-white'>
                                <Link to={routePath} spy={true} smooth={true} duration={500}>
                                    {routeDisplayName}
                                </Link>
                            </div>
                        );
                    })}
                </div>
            </div>
        )}
    </>
  )
}