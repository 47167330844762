// Pages
import Splash from "../views/Splash";
import About from "../views/About";
import Team from "../views/Team";
import Footer from "../views/Footer";
import ChatBot from "../views/ChatBot";
import Marketplace from "../views/Marketplace";
import Contact from "../views/Contact";

function Home() {

    return (
        <>
            <Splash />
            <About />
            <Marketplace />
            <ChatBot />
            <Team />
            <Contact />
            <Footer />
        </>
    )
}

export default Home;